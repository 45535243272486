<template>
  <div class="">
    <nav-bar></nav-bar>

    <div class="slider">
      <el-carousel class="carousel-hg">
        <el-carousel-item>
          <div class="">
            <img src="../assets/slider/61SvgZVr3vL._SX3000_.jpg" alt="" />
          </div>
        </el-carousel-item>

        <el-carousel-item>
          <img src="../assets/slider/71JwtqdqkmL._SX3000_.jpg" alt="" />
        </el-carousel-item>

        <el-carousel-item>
          <img src="../assets/slider/71tn+C4kR8L._SX3000_.jpg" alt="" />
        </el-carousel-item>

        <el-carousel-item>
          <img src="../assets/slider/71A0fX04gLL._SX3000_.jpg" alt="" />
        </el-carousel-item>

        <el-carousel-item>
          <img src="../assets/slider/71m6QLfdWZL._SX3000_.jpg" alt="" />
        </el-carousel-item>
      </el-carousel>

      <!-- <h4>Best Sellers in Home</h4> -->
      <div class="products-slider-sm d-block d-lg-none">
        <div class="products-slider-item">
          <a href="https://www.amazon.ca/electronics-deals-electronics-sale-tv-sale/b/?_encoding=UTF8&ie=UTF8&node=2055586011&discounts-widget=%2522%257B%255C%2522state%255C%2522%253A%257B%255C%2522refinementFilters%255C%2522%253A%257B%257D%257D%252C%255C%2522version%255C%2522%253A1%257D%2522&pd_rd_w=kv5Lz&content-id=amzn1.sym.c5e46070-5635-44fb-8fc0-d4f7f70bf1e3&pf_rd_p=c5e46070-5635-44fb-8fc0-d4f7f70bf1e3&pf_rd_r=PX69H726F61TE4024C5G&pd_rd_wg=ljdsW&pd_rd_r=1a245f22-97ee-4941-96b8-9173f290d38a&ref_=pd_hp_d_atf_unk">
              <div class="product-item">
                <h4>Shop our holiday gifts</h4>
                <div class="img-box">
                  <img src="../assets/newProducts/mivne-cc-d1x-hol24-ca._SY304_CB543151152_.jpg" alt="" />
                </div>
              </div>
            </a>
        </div>

        <div class="products-slider-item">
          <a href="https://www.amazon.ca/gcx/Under-50/gfhz/events/?_encoding=UTF8&categoryId=Hol-under-50&content-id=amzn1.sym.5ed8da78-a5ee-40d1-8993-fbdc7102ecc2&pd_rd_r=1a245f22-97ee-4941-96b8-9173f290d38a&pd_rd_w=17iKR&pd_rd_wg=ljdsW&pf_rd_p=5ed8da78-a5ee-40d1-8993-fbdc7102ecc2&pf_rd_r=PX69H726F61TE4024C5G&ref_=pd_hp_d_atf_unk&scrollState=eyJpdGVtSW5kZXgiOjAsInNjcm9sbE9mZnNldCI6LTE3Mi4xNDk5OTM4OTY0ODQzOH0%3D&sectionManagerState=eyJzZWN0aW9uVHlwZUVuZEluZGV4Ijp7ImFtYWJvdCI6MH19">
                <div class="product-item">

              
              <h4>Explore deals on gifts</h4>
              <div class="img-box">
                <img src="../assets/header/XCM_CUTTLE_1451609_2462586_379x304_1X_en_CA._SY304_CB633175008_.jpg" alt="" />
              </div>
            </div>
           </a>
        </div>

        <div class="products-slider-item">
          <a href="https://www.amazon.ca/electronics-deals-electronics-sale-tv-sale/b/?_encoding=UTF8&ie=UTF8&node=2055586011&discounts-widget=%2522%257B%255C%2522state%255C%2522%253A%257B%255C%2522refinementFilters%255C%2522%253A%257B%257D%257D%252C%255C%2522version%255C%2522%253A1%257D%2522&pd_rd_w=kv5Lz&content-id=amzn1.sym.c5e46070-5635-44fb-8fc0-d4f7f70bf1e3&pf_rd_p=c5e46070-5635-44fb-8fc0-d4f7f70bf1e3&pf_rd_r=PX69H726F61TE4024C5G&pd_rd_wg=ljdsW&pd_rd_r=1a245f22-97ee-4941-96b8-9173f290d38a&ref_=pd_hp_d_atf_unk">
            <div class="product-item">
              <h4>Shop our Electronics Gift </h4>
              <div class="img-box">
                <img src="../assets/header/XCM_CUTTLE_1537491_2879615_379x304_1X_en_CA._SY304_CB614891515_.jpg" alt="" />
              </div>
            </div>
          </a>
        </div>

        <div class="products-slider-item">
          <a href="https://www.amazon.ca/electronics-deals-electronics-sale-tv-sale/b/?_encoding=UTF8&ie=UTF8&node=2055586011&discounts-widget=%2522%257B%255C%2522state%255C%2522%253A%257B%255C%2522refinementFilters%255C%2522%253A%257B%257D%257D%252C%255C%2522version%255C%2522%253A1%257D%2522&pd_rd_w=kv5Lz&content-id=amzn1.sym.c5e46070-5635-44fb-8fc0-d4f7f70bf1e3&pf_rd_p=c5e46070-5635-44fb-8fc0-d4f7f70bf1e3&pf_rd_r=PX69H726F61TE4024C5G&pd_rd_wg=ljdsW&pd_rd_r=1a245f22-97ee-4941-96b8-9173f290d38a&ref_=pd_hp_d_atf_unk">
            <div class="product-item">
              <h4>Shop our Electronics Gift </h4>
              <div class="img-box">
                <img src="../assets/header/XCM_CUTTLE_1537491_2879615_379x304_1X_en_CA._SY304_CB614891515_.jpg" alt="" />
              </div>
            </div>
          </a>
        </div>

        <div class="products-slider-item">
          <a href="https://www.amazon.ca/b/?_encoding=UTF8&ie=UTF8&node=7653517011&discounts-widget=%2522%257B%255C%2522state%255C%2522%253A%257B%255C%2522refinementFilters%255C%2522%253A%257B%257D%257D%252C%255C%2522version%255C%2522%253A1%257D%2522&pd_rd_w=kv5Lz&content-id=amzn1.sym.c5e46070-5635-44fb-8fc0-d4f7f70bf1e3&pf_rd_p=c5e46070-5635-44fb-8fc0-d4f7f70bf1e3&pf_rd_r=PX69H726F61TE4024C5G&pd_rd_wg=ljdsW&pd_rd_r=1a245f22-97ee-4941-96b8-9173f290d38a&ref_=pd_hp_d_atf_unk">
            <div class="product-item">
              <h4>Shop our Beauty store</h4>
              <div class="img-box">
                <img src="../assets/header/XCM_CUTTLE_1699742_3644560_379x304_1X_en_CA._SY304_CB580089781_.jpg" alt="" />
              </div>
            </div>
          </a>
        </div>

        <div class="products-slider-item">
          <a href="https://www.amazon.ca/electronics-deals-electronics-sale-tv-sale/b/?_encoding=UTF8&ie=UTF8&node=2055586011&discounts-widget=%2522%257B%255C%2522state%255C%2522%253A%257B%255C%2522refinementFilters%255C%2522%253A%257B%257D%257D%252C%255C%2522version%255C%2522%253A1%257D%2522&pd_rd_w=kv5Lz&content-id=amzn1.sym.c5e46070-5635-44fb-8fc0-d4f7f70bf1e3&pf_rd_p=c5e46070-5635-44fb-8fc0-d4f7f70bf1e3&pf_rd_r=PX69H726F61TE4024C5G&pd_rd_wg=ljdsW&pd_rd_r=1a245f22-97ee-4941-96b8-9173f290d38a&ref_=pd_hp_d_atf_unk">
            <div class="product-item">
              <h4>Shop our Electronics Gift </h4>
              <div class="img-box">
                <img src="../assets/header/XCM_CUTTLE_1537491_2879615_379x304_1X_en_CA._SY304_CB614891515_.jpg" alt="" />
              </div>
            </div>
          </a>
        </div>

        <div class="products-slider-item">
          <a href="https://www.amazon.ca/electronics-deals-electronics-sale-tv-sale/b/?_encoding=UTF8&ie=UTF8&node=2055586011&discounts-widget=%2522%257B%255C%2522state%255C%2522%253A%257B%255C%2522refinementFilters%255C%2522%253A%257B%257D%257D%252C%255C%2522version%255C%2522%253A1%257D%2522&pd_rd_w=kv5Lz&content-id=amzn1.sym.c5e46070-5635-44fb-8fc0-d4f7f70bf1e3&pf_rd_p=c5e46070-5635-44fb-8fc0-d4f7f70bf1e3&pf_rd_r=PX69H726F61TE4024C5G&pd_rd_wg=ljdsW&pd_rd_r=1a245f22-97ee-4941-96b8-9173f290d38a&ref_=pd_hp_d_atf_unk">
            <div class="product-item">
              <h4>Shop our Electronics Gift </h4>
              <div class="img-box">
                <img src="../assets/header/XCM_CUTTLE_1537491_2879615_379x304_1X_en_CA._SY304_CB614891515_.jpg" alt="" />
              </div>
            </div>
          </a>
        </div>
      </div>

      <div class="products sm-none">
        <div class="row">
          <div class="col-4 col-md-3 col-lg-3">
            <a href="https://www.amazon.ca/electronics-deals-electronics-sale-tv-sale/b/?_encoding=UTF8&ie=UTF8&node=2055586011&discounts-widget=%2522%257B%255C%2522state%255C%2522%253A%257B%255C%2522refinementFilters%255C%2522%253A%257B%257D%257D%252C%255C%2522version%255C%2522%253A1%257D%2522&pd_rd_w=kv5Lz&content-id=amzn1.sym.c5e46070-5635-44fb-8fc0-d4f7f70bf1e3&pf_rd_p=c5e46070-5635-44fb-8fc0-d4f7f70bf1e3&pf_rd_r=PX69H726F61TE4024C5G&pd_rd_wg=ljdsW&pd_rd_r=1a245f22-97ee-4941-96b8-9173f290d38a&ref_=pd_hp_d_atf_unk">
              <div class="product-item">
                <h3>Shop our holiday gifts</h3>
                <div class="img-box">
                  <img src="../assets/newProducts/mivne-cc-d1x-hol24-ca._SY304_CB543151152_.jpg" alt="" />
                </div>
                <a href="https://www.amazon.ca/electronics-deals-electronics-sale-tv-sale/b/?_encoding=UTF8&ie=UTF8&node=2055586011&discounts-widget=%2522%257B%255C%2522state%255C%2522%253A%257B%255C%2522refinementFilters%255C%2522%253A%257B%257D%257D%252C%255C%2522version%255C%2522%253A1%257D%2522&pd_rd_w=kv5Lz&content-id=amzn1.sym.c5e46070-5635-44fb-8fc0-d4f7f70bf1e3&pf_rd_p=c5e46070-5635-44fb-8fc0-d4f7f70bf1e3&pf_rd_r=PX69H726F61TE4024C5G&pd_rd_wg=ljdsW&pd_rd_r=1a245f22-97ee-4941-96b8-9173f290d38a&ref_=pd_hp_d_atf_unk">shop now</a>
              </div>
            </a>
          </div>

          <div class="col-4 col-md-3 col-lg-3">
              <a href="https://www.amazon.ca/gcx/Under-50/gfhz/events/?_encoding=UTF8&categoryId=Hol-under-50&content-id=amzn1.sym.5ed8da78-a5ee-40d1-8993-fbdc7102ecc2&pd_rd_r=1a245f22-97ee-4941-96b8-9173f290d38a&pd_rd_w=17iKR&pd_rd_wg=ljdsW&pf_rd_p=5ed8da78-a5ee-40d1-8993-fbdc7102ecc2&pf_rd_r=PX69H726F61TE4024C5G&ref_=pd_hp_d_atf_unk&scrollState=eyJpdGVtSW5kZXgiOjAsInNjcm9sbE9mZnNldCI6LTE3Mi4xNDk5OTM4OTY0ODQzOH0%3D&sectionManagerState=eyJzZWN0aW9uVHlwZUVuZEluZGV4Ijp7ImFtYWJvdCI6MH19">
                <div class="product-item">

              
              <h3>Explore deals on gifts</h3>
              <div class="img-box">
                <img src="../assets/header/XCM_CUTTLE_1451609_2462586_379x304_1X_en_CA._SY304_CB633175008_.jpg" alt="" />
              </div>
              <a href="">shop now</a>
            </div>
           </a>
          </div>

          <div class="col-4 col-md-3 col-lg-3">
            <a href="https://www.amazon.ca/electronics-deals-electronics-sale-tv-sale/b/?_encoding=UTF8&ie=UTF8&node=2055586011&discounts-widget=%2522%257B%255C%2522state%255C%2522%253A%257B%255C%2522refinementFilters%255C%2522%253A%257B%257D%257D%252C%255C%2522version%255C%2522%253A1%257D%2522&pd_rd_w=kv5Lz&content-id=amzn1.sym.c5e46070-5635-44fb-8fc0-d4f7f70bf1e3&pf_rd_p=c5e46070-5635-44fb-8fc0-d4f7f70bf1e3&pf_rd_r=PX69H726F61TE4024C5G&pd_rd_wg=ljdsW&pd_rd_r=1a245f22-97ee-4941-96b8-9173f290d38a&ref_=pd_hp_d_atf_unk">
            <div class="product-item">
              <h3>Shop our Electronics Gift </h3>
              <div class="img-box">
                <img src="../assets/header/XCM_CUTTLE_1537491_2879615_379x304_1X_en_CA._SY304_CB614891515_.jpg" alt="" />
              </div>
              <a href="">shop now</a>
            </div>
          </a>
          </div>

          <div class="col-4 col-md-3 col-lg-3">
            <a href="https://www.amazon.ca/b/?_encoding=UTF8&ie=UTF8&node=7653517011&discounts-widget=%2522%257B%255C%2522state%255C%2522%253A%257B%255C%2522refinementFilters%255C%2522%253A%257B%257D%257D%252C%255C%2522version%255C%2522%253A1%257D%2522&pd_rd_w=kv5Lz&content-id=amzn1.sym.c5e46070-5635-44fb-8fc0-d4f7f70bf1e3&pf_rd_p=c5e46070-5635-44fb-8fc0-d4f7f70bf1e3&pf_rd_r=PX69H726F61TE4024C5G&pd_rd_wg=ljdsW&pd_rd_r=1a245f22-97ee-4941-96b8-9173f290d38a&ref_=pd_hp_d_atf_unk">
            <div class="product-item">
              <h3>Shop our Beauty store</h3>
              <div class="img-box">
                <img src="../assets/header/XCM_CUTTLE_1699742_3644560_379x304_1X_en_CA._SY304_CB580089781_.jpg" alt="" />
              </div>
              <a href="">shop now</a>
            </div>
          </a>
          </div>
        </div>
      </div>
    </div>

    <div class="products-slider-wraper mt-50">
      <h4>Today's Deals</h4>
      <div class="products-slider">
        <div class="products-slider-item" v-for="product in beautyProducts" :key="product.title">
          <a :href="product.url">
            <div class="img-box">
            <img :src=" getImagePath(product.thumbnailImage)" alt="" />
          </div>
          <div class="offer">
            <span>{{product.dis}}% off</span>
            <h6>limted time deal</h6>
          </div>
          <div class="price">
            <span class="main-price"><span class="sup">$</span>{{ Math.floor(product.price.value)}}</span
            ><span class="sup">{{ ((product.price.value).toString()).includes('.') ? (product.price.value).toString().split('.')[1] : '' }}</span
            >
          </div>
          <p>{{ (product.title).split(' ').slice(0, 6).join(' ') }}</p>
          </a>
        </div>

        


        
        
       


      </div>
    </div>

    <div class="products-slider-wraper">
      <h4>Best Sellers in Home</h4>
      <div class="products-slider slide">
        <div class="products-slider-item" >
          <router-link to="/product" @click.prevent="toTop" >
            <div class="img-box auto-height">
              <img src="../assets/newProducts/612s8xKn88L._AC_SY450_.jpg" alt="" />
            </div>
          </router-link>
        </div>
        <div class="products-slider-item" v-for="item in products" :key="item.name" >
          <a :href="item.url">
            <div class="img-box auto-height">
            <img :src=" getImagePath(item.thumbnailImage)" alt="" />
          </div>
          </a>
        </div>


      


       

  
        </div>
    </div>

    <div class="banner d-lg-none">
      <div class="img-banner">
        <img
          src="../assets/banner.jpg"
          style="width: 100%; height: 100%"
          alt=""
        />
      </div>
    </div>

    <div class="group-products">
      <div class="row ps-0 pe-0">
        <div class="col-12 col-md-6 col-lg-3 ps-0 pe-0">
          <div class="item me-3">
            <h4>Best Sellers in Movies</h4>
            <div class="item-products">
              <div class="row">
                <div class="col-6">
                  <div class="item-product">
                    <a href="https://www.amazon.ca/Dune-Part-Ultra-Bilingual-Blu-ray/dp/B0CX25JSPN/ref=sr_1_5?crid=1ML6CSA2G5RUP&dib=eyJ2IjoiMSJ9.GErNSXNfbr-Tf90Z3qoivpHj_KTby6fAp-LWGabzQ4kw6upmg_LK4EYWvY8w3osZbPU4USjB35md-bXyZvgRyZ-fWC-CW5DAHZ_2rEOCobIunADttPx-V8HguKOSBz9k00yzVi26ufpd0MrGXHU7_hbL64u45IBUY4-yrr5flx7-M8ZRPwKQfv5qy4qtqjfhgqfXbIgYuHRc8h7jNV1a28BIPqSG0hKx06Jaok80ZYoP805hVx6LZo1U7uXMYG2EVRfIEk3Era0u7ALUqZOONWuRm80KoP4OuK1odj_pRBg._J_c3ClH5tk_44xgV-zObKDc3xqjTJv_b8Cv3aZV4ys&dib_tag=se&keywords=movies&qid=1733745322&sprefix=movies%2Caps%2C297&sr=8-5">

                    
                    <div class="img-box">
                      <img src="../assets/newProducts/71fIi8zfIdL._AC_SX342_.jpg" alt="" />
                    </div>
                  </a>
                  </div>
                </div>

                <div class="col-6">
                  <div class="item-product">
                    <a href="https://www.amazon.ca/Dune-Part-Ultra-Bilingual-Blu-ray/dp/B0CX25JSPN/ref=sr_1_5?crid=1ML6CSA2G5RUP&dib=eyJ2IjoiMSJ9.GErNSXNfbr-Tf90Z3qoivpHj_KTby6fAp-LWGabzQ4kw6upmg_LK4EYWvY8w3osZbPU4USjB35md-bXyZvgRyZ-fWC-CW5DAHZ_2rEOCobIunADttPx-V8HguKOSBz9k00yzVi26ufpd0MrGXHU7_hbL64u45IBUY4-yrr5flx7-M8ZRPwKQfv5qy4qtqjfhgqfXbIgYuHRc8h7jNV1a28BIPqSG0hKx06Jaok80ZYoP805hVx6LZo1U7uXMYG2EVRfIEk3Era0u7ALUqZOONWuRm80KoP4OuK1odj_pRBg._J_c3ClH5tk_44xgV-zObKDc3xqjTJv_b8Cv3aZV4ys&dib_tag=se&keywords=movies&qid=1733745322&sprefix=movies%2Caps%2C297&sr=8-5">

                    
                    <div class="img-box">
                      <img src="../assets/newProducts/91QZUoO8ZBL.__AC_SX300_SY300_QL70_ML2_.jpg" alt="" />
                    </div>
                  </a>
                  </div>
                </div>

                <div class="col-6">
                  <div class="item-product">
                    <a href="https://www.amazon.ca/Dune-Part-Ultra-Bilingual-Blu-ray/dp/B0CX25JSPN/ref=sr_1_5?crid=1ML6CSA2G5RUP&dib=eyJ2IjoiMSJ9.GErNSXNfbr-Tf90Z3qoivpHj_KTby6fAp-LWGabzQ4kw6upmg_LK4EYWvY8w3osZbPU4USjB35md-bXyZvgRyZ-fWC-CW5DAHZ_2rEOCobIunADttPx-V8HguKOSBz9k00yzVi26ufpd0MrGXHU7_hbL64u45IBUY4-yrr5flx7-M8ZRPwKQfv5qy4qtqjfhgqfXbIgYuHRc8h7jNV1a28BIPqSG0hKx06Jaok80ZYoP805hVx6LZo1U7uXMYG2EVRfIEk3Era0u7ALUqZOONWuRm80KoP4OuK1odj_pRBg._J_c3ClH5tk_44xgV-zObKDc3xqjTJv_b8Cv3aZV4ys&dib_tag=se&keywords=movies&qid=1733745322&sprefix=movies%2Caps%2C297&sr=8-5">

                    
                    <div class="img-box">
                      <img src="../assets/newProducts/81Lv3OaLW-L._AC_SX342_.jpg" alt="" />
                    </div>
                  </a>
                  </div>
                </div>

                <div class="col-6">
                  <div class="item-product">
                    <a href="https://www.amazon.ca/Dune-Part-Ultra-Bilingual-Blu-ray/dp/B0CX25JSPN/ref=sr_1_5?crid=1ML6CSA2G5RUP&dib=eyJ2IjoiMSJ9.GErNSXNfbr-Tf90Z3qoivpHj_KTby6fAp-LWGabzQ4kw6upmg_LK4EYWvY8w3osZbPU4USjB35md-bXyZvgRyZ-fWC-CW5DAHZ_2rEOCobIunADttPx-V8HguKOSBz9k00yzVi26ufpd0MrGXHU7_hbL64u45IBUY4-yrr5flx7-M8ZRPwKQfv5qy4qtqjfhgqfXbIgYuHRc8h7jNV1a28BIPqSG0hKx06Jaok80ZYoP805hVx6LZo1U7uXMYG2EVRfIEk3Era0u7ALUqZOONWuRm80KoP4OuK1odj_pRBg._J_c3ClH5tk_44xgV-zObKDc3xqjTJv_b8Cv3aZV4ys&dib_tag=se&keywords=movies&qid=1733745322&sprefix=movies%2Caps%2C297&sr=8-5">

                    
                    <div class="img-box">
                      <img src="../assets/newProducts/71nK9pG7UjL._AC_SX342_.jpg" alt="" />
                    </div>
                  </a>
                  </div>
                </div>
              </div>
            </div>

            <a href="">See more from Amazon Renewed</a>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-3 ps-0 pe-0">
          <div class="item me-3">
            <h4>Save on pre-owned home appliances</h4>
            <div class="row">
              <div class="col-12">
                <div class="item-product" style="height:310px">
                  <a href="https://www.amazon.ca/Fitbit-Management-Intensity-Tracking-Midnight/dp/B0B75RC9C3?pd_rd_w=wBi1g&content-id=amzn1.sym.39526d57-066e-456c-bc01-eab1026394a8&pf_rd_p=39526d57-066e-456c-bc01-eab1026394a8&pf_rd_r=1CJAW07MSEFBZN2RRK4K&pd_rd_wg=vBdIK&pd_rd_r=e6f9b7ff-e721-40c1-bf47-36b627638715&pd_rd_i=B0B75RC9C3&ref_=pd_hp_d_btf_unk_B0B75RC9C3&th=1">
                    <div class="img-box">
                    <img src="../assets/newProducts/61AeGQhwjxL.__AC_SX300_SY300_QL70_ML2_.jpg" alt="" />
                  </div>
                  </a>
                  <!-- <h6>Kitchen & Dining</h6> -->
                </div>
              </div>
              
            </div>
            <!-- <div class="item-products">
              <div class="row">
                <div class="col-6">
                  <div class="item-product">
                    <div class="img-box">
                      <img src="../assets/51f0svBCFGL._SR480,440_.png" alt="" />
                    </div>
                    <h6>Kitchen & Dining</h6>
                  </div>
                </div>

                <div class="col-6">
                  <div class="item-product">
                    <div class="img-box">
                      <img src="../assets/51f0svBCFGL._SR480,440_.png" alt="" />
                    </div>
                    <h6>Kitchen & Dining</h6>
                  </div>
                </div>

                <div class="col-6">
                  <div class="item-product">
                    <div class="img-box">
                      <img src="../assets/51f0svBCFGL._SR480,440_.png" alt="" />
                    </div>
                    <h6>Kitchen & Dining</h6>
                  </div>
                </div>

                <div class="col-6">
                  <div class="item-product">
                    <div class="img-box">
                      <img src="../assets/51f0svBCFGL._SR480,440_.png" alt="" />
                    </div>
                    <h6>Kitchen & Dining</h6>
                  </div>
                </div>
              </div>
            </div> -->

            <!-- <a href="">See more from Amazon Renewed</a> -->
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-3 ps-0 pe-0">
          <div class="item me-3">
            <div class="item-products">
             
              <div class="d-none d-lg-block">
                <div class="row">
                 
                  <div class="col-6">
                    <div class="item-product">
                      <a href="https://www.amazon.ca/Amazon-Essentials-Flannel-Pajama-Buffalo/dp/B07BJKKD2T/?_encoding=UTF8&pd_rd_w=xHOmf&content-id=amzn1.sym.0a4889f1-d999-4e04-a718-34da7dae1e8b&pf_rd_p=0a4889f1-d999-4e04-a718-34da7dae1e8b&pf_rd_r=9KFT5BXHAYBTK6HFKPHK&pd_rd_wg=rdW0R&pd_rd_r=20131182-ba67-4bc0-abb7-114c16958807&ref_=pd_hp_d_btf_crs_zg_bs_8604903011">
                      <div class="img-box">
                        <img
                          src="../assets/newProducts/81dbk+fTVuL._AC_SY170_.jpg"
                          alt=""
                        />
                      </div>
                    </a>
                    </div>
                  </div>

                  <div class="col-6">
                    <div class="item-product">
                      <a href="https://www.amazon.ca/Good-Luck-Sock-Hockey-Sticks/dp/B07Z63YQQR/?_encoding=UTF8&pd_rd_w=xHOmf&content-id=amzn1.sym.0a4889f1-d999-4e04-a718-34da7dae1e8b&pf_rd_p=0a4889f1-d999-4e04-a718-34da7dae1e8b&pf_rd_r=9KFT5BXHAYBTK6HFKPHK&pd_rd_wg=rdW0R&pd_rd_r=20131182-ba67-4bc0-abb7-114c16958807&ref_=pd_hp_d_btf_crs_zg_bs_8604903011">
                      <div class="img-box">
                        <img
                          src="../assets/newProducts/71ggnk2vv7L._AC_SY170_.jpg"
                          alt=""
                        />
                      </div>
                    </a>
                    </div>
                  </div>

                  <div class="col-6">
                    <div class="item-product">
                      <a href="https://www.amazon.ca/Carhartt-Acrylic-Watch-Brown-Size/dp/B002G9UDY6/?_encoding=UTF8&pd_rd_w=xHOmf&content-id=amzn1.sym.0a4889f1-d999-4e04-a718-34da7dae1e8b&pf_rd_p=0a4889f1-d999-4e04-a718-34da7dae1e8b&pf_rd_r=9KFT5BXHAYBTK6HFKPHK&pd_rd_wg=rdW0R&pd_rd_r=20131182-ba67-4bc0-abb7-114c16958807&ref_=pd_hp_d_btf_crs_zg_bs_8604903011">
                      <div class="img-box">
                        <img
                          src="../assets/newProducts/71I6cozozUL._AC_SY170_.jpg"
                          alt=""
                        />
                      </div>
                    </a>
                    </div>
                  </div>

                  <div class="col-6">
                    <div class="item-product">
                      <a href="https://www.amazon.ca/Linnhoy-Christmas-Pajamas-Family-Matching/dp/B0D9PX6CD4/?_encoding=UTF8&pd_rd_w=xHOmf&content-id=amzn1.sym.0a4889f1-d999-4e04-a718-34da7dae1e8b&pf_rd_p=0a4889f1-d999-4e04-a718-34da7dae1e8b&pf_rd_r=9KFT5BXHAYBTK6HFKPHK&pd_rd_wg=rdW0R&pd_rd_r=20131182-ba67-4bc0-abb7-114c16958807&ref_=pd_hp_d_btf_crs_zg_bs_8604903011">
                      <div class="img-box">
                        <img
                          src="../assets/newProducts/71f8pLwmNeL._AC_SY170_.jpg"
                          alt=""
                        />
                      </div>
                    </a>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <a href="">See more from Amazon Renewed</a>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-3 ps-0 pe-0">
          <div class="item">
            <h4>Gift ideas in Card Games</h4>
            <div class="item-products">
              <div class="row">
                <div class="col-6">
                  <div class="item-product">
                    <a href="https://www.amazon.ca/Mattel-Games-Family-Storage-Travel-Friendly/dp/B00CTH0A1Q/?_encoding=UTF8&pd_rd_w=wB1ea&content-id=amzn1.sym.7fa0f5ce-d1cb-4c58-96d6-22acce2a0fb5&pf_rd_p=7fa0f5ce-d1cb-4c58-96d6-22acce2a0fb5&pf_rd_r=1CJAW07MSEFBZN2RRK4K&pd_rd_wg=vBdIK&pd_rd_r=e6f9b7ff-e721-40c1-bf47-36b627638715&ref_=pd_hp_d_btf_gcx_gw_concept_node_asin_pi_search_na&th=1">

                    <div class="img-box">
                      <img src="../assets/newProducts/81kTVgT-W5L._AC_SY879_.jpg" alt="" />
                    </div>
                  </a>
                  </div>
                </div>

                <div class="col-6">
                  <div class="item-product">
                    <a href="https://www.amazon.ca/Mattel-Games-Families-Challenging-Rummy-Style/dp/B00A7GPVXS/?_encoding=UTF8&pd_rd_w=wB1ea&content-id=amzn1.sym.7fa0f5ce-d1cb-4c58-96d6-22acce2a0fb5&pf_rd_p=7fa0f5ce-d1cb-4c58-96d6-22acce2a0fb5&pf_rd_r=1CJAW07MSEFBZN2RRK4K&pd_rd_wg=vBdIK&pd_rd_r=e6f9b7ff-e721-40c1-bf47-36b627638715&ref_=pd_hp_d_btf_gcx_gw_concept_node_asin_pi_search_na">

                    <div class="img-box">
                      <img src="../assets/newProducts/61ALh6rTuAL._AC_SX522_.jpg" alt="" />
                    </div>
                  </a>
                  </div>
                </div>

                <div class="col-6">
                  <div class="item-product">
                    <a href="https://www.amazon.ca/Outset-Media-Family-Charades-Card/dp/B00H5464TU/?_encoding=UTF8&pd_rd_w=wB1ea&content-id=amzn1.sym.7fa0f5ce-d1cb-4c58-96d6-22acce2a0fb5&pf_rd_p=7fa0f5ce-d1cb-4c58-96d6-22acce2a0fb5&pf_rd_r=1CJAW07MSEFBZN2RRK4K&pd_rd_wg=vBdIK&pd_rd_r=e6f9b7ff-e721-40c1-bf47-36b627638715&ref_=pd_hp_d_btf_gcx_gw_concept_node_asin_pi_search_na">

                    <div class="img-box">
                      <img src="../assets/newProducts/71JiKGWQm6L._AC_SY879_.jpg" alt="" />
                    </div>
                  </a>
                  </div>
                </div>

                <div class="col-6">
                  <div class="item-product">
                    <a href="https://www.amazon.ca/Card-Makers-Adults-Night-Numbers/dp/B09C932KCR/?_encoding=UTF8&pd_rd_w=wB1ea&content-id=amzn1.sym.7fa0f5ce-d1cb-4c58-96d6-22acce2a0fb5&pf_rd_p=7fa0f5ce-d1cb-4c58-96d6-22acce2a0fb5&pf_rd_r=1CJAW07MSEFBZN2RRK4K&pd_rd_wg=vBdIK&pd_rd_r=e6f9b7ff-e721-40c1-bf47-36b627638715&ref_=pd_hp_d_btf_gcx_gw_concept_node_asin_pi_search_na">

                    <div class="img-box">
                      <img src="../assets/newProducts/812-VGlWRBL._AC_SY741_.jpg" alt="" />
                    </div>
                  </a>
                  </div>
                </div>

              </div>
            </div>

            <a href="">See more from Amazon Renewed</a>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="products-slider-wraper mt-50">
      <h4>Today's Deals</h4>
      <div class="products-slider">
        <div class="products-slider-item" v-for="item in products.products" :key="item.id">
          <div class="img-box">
            <img :src="'data:image '+ item.image" alt="" />
          </div>
          <div class="offer">
            <span> {{item.discount_percentage}} % off</span>
            <h6>limted time deal</h6>
          </div>
  
          <div class="price">
            <span class="main-price"><span class="sup">$</span>{{item.price}}</span
            ><span class="sup">99</span
            ><span
              class="ms-2 me-1"
              style="text-transform: capitalize; color: #929292"
              >list:</span
            ><del>${{item.old_price}}</del>
          </div>
  
          <p>{{item.description}}</p>
        </div>
  
       
       
      </div>
  
  
    </div> -->

  </div>


<footer-sec></footer-sec>


</template>






<script>
// @ is an alias to /src
import footerSec from "@/components/footer.vue";
import navBar from "@/components/nav.vue";

import { ref } from "vue";
import { ElMessageBox } from "element-plus";

import productsData from '@/data/product.json';
import productsBeautyData from '@/data/productBeauty.json';



export default {
  name: 'HomeView',
  components: {
    footerSec,
    navBar,
    
  },
  setup() {
    const drawer = ref(false);
    const dialogVisible = ref(false);

    const products = productsData
    const beautyProducts = productsBeautyData

    console.log(products.products)

    const cart =[]

    
   function  addToCart(product) {
      const item = this.cart.find(i => i.id === product.id);
      if (item) {
        item.quantity += 1;
      } else {
        this.cart.push({ ...product, quantity: 1 });
      }
    }

   function removeFromCart(product) {
      const index = this.cart.findIndex(i => i.id === product.id);
      if (index !== -1) {
        this.cart.splice(index, 1);
      }
    }


    function toTop(){
      window.scrollTo(0,0)
    }


    function  getImagePath(relativePath) {
      // Use Webpack to resolve paths from the assets folder
      return require(`@/assets/${relativePath}`);
    }

    return {
      dialogVisible,
      drawer,
      products,
      cart,
      addToCart,
      removeFromCart,
      getImagePath,
      beautyProducts,
      toTop
    };
  },
};


$(document).ready(function () {
  $(".products-slider").slick({
    dots: false,
    infinite: true,
    arrows: true,
    speed: 300,
    slidesToShow: 6,
    slidesToScroll: 3,
    prevArrow:
      '<button type="button" class="slick-Prev"><i class="fa-solid fa-angle-left"></i></button>',
    nextArrow:
      '<button type="button" class="slick-Next"><i class="fa-solid fa-angle-right"></i></button>',

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  });

  $(".products-slider-sm").slick({
    dots: false,
    infinite: true,
    arrows: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 3,

    responsive: [
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  });
});
</script>



<style>

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "amazon regular";
  src: url("../assets/fonts/Amazon_Typefaces_Complete_Font_Set_Mar2020/Amazon_Typefaces_Complete_Font_Set_Mar2020/Ember/AmazonEmber_Rg.ttf")
    format("truetype");
}

@font-face {
  font-family: "amazon medium";
  src: url("../assets/fonts/Amazon_Typefaces_Complete_Font_Set_Mar2020/Amazon_Typefaces_Complete_Font_Set_Mar2020/Ember/Amazon-Ember-Medium.ttf")
    format("truetype");
}

@font-face {
  font-family: "amazon bold";
  src: url("../assets/fonts/Amazon_Typefaces_Complete_Font_Set_Mar2020/Amazon_Typefaces_Complete_Font_Set_Mar2020/Ember/AmazonEmber_Bd.ttf")
    format("truetype");
}

body {
  background-color: #E3E6E6 !important;
  font-family: "amazon regular", sans-serif !important;
}

.conatiner {
  max-width: 1170px;
  margin: auto;
}

a {
  text-decoration: none !important;
  color: inherit !important;
}

.el-carousel__arrow--left,
.el-carousel__arrow--right {
  width: 84px !important;
  height: 240px !important;
  border-radius: 0px !important;
  background-color: transparent !important;
  color: #232f3e !important;
  top: 21.5% !important;
}

.el-carousel__arrow--right:focus {
  border: #007185 3px solid !important;
}

.el-carousel__arrow--left:focus {
  border: #007185 3px solid !important;
}

.el-carousel__arrow--right .el-icon,
.el-carousel__arrow--left .el-icon {
  width: 50px !important;
  height: 50px !important;
  font-size: 40px !important;
  /* color: white !important; */
}

.slider {
  position: relative;
}

.slider div img {
  width: 100%;
  height: 100%;
}

.products {
  position: absolute;
  /* bottom: -36px; */
  bottom: -83px;
  padding: 20px;
}

.products .product-item {
  padding: 20px;
  background-color: white;
}

.products .product-item h3 {
  font-weight: 600;
  text-transform: capitalize;
  font-size: 22px;
  margin-bottom: 20px;
}

.products .product-item .img-box {
  width: 100%;
}

.products .product-item .img-box img {
  width: 100%;
  height: 100%;
}

.products .product-item a {
  display: inline-block;
  margin-top: 20px;
  color: #007185 !important;
  text-transform: capitalize;
}

.products-slider-wraper {
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
  padding: 10px;
  background-color: white;
}

/* .mt-50{
  margin-top: 80px;
} */

.products-slider-wraper h4 {
  font-weight: 700;
  margin-bottom: -11px;
  margin-top: 10px;
  padding-left: 20px;
  font-size: 20px;
  text-align: left;
}

.products-slider {
}

.products-slider .products-slider-item {
  padding: 10px;
  background-color: white;
  margin-bottom: 5px;
  margin: 10px;
  width: 100% !important;
  cursor: pointer;
}

.auto-height {
  height: 120px;
  background-color: white !important;
}

.products-slider .img-box {
  width: 100%;
  height: 200px;
  margin-bottom: 15px;
  background-color: #f7f8f8;
  padding: 10px;
}

.products-slider .img-box img {
  width: 100%;
  height: 100%;
}

.products-slider .offer {
  color: #cc0c39;
  margin-bottom: 10px;
  text-transform: capitalize;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: start;
}

.products-slider .offer span {
  background-color: #cc0c39;
  color: white;
  padding: 4px;
  display: inline-block;
  border-radius: 3px;
  font-size: 12px;
}

.products-slider .offer h6 {
  font-weight: 700;
  margin-left: 8px;
  margin-bottom: 0;
  font-size: 14px;
}

.products-slider .price {
  display: flex;
}

.products-slider .price .main-price {
  font-size: 20px;
  font-weight: 600;
  color: #0f1111;
}

.products-slider .price .sup {
  font-size: 14px;
}

.products-slider .price del {
  color: #929292;
}

.products-slider p {
  margin-top: 5px;
  margin-bottom: 0;
  font-size: 13px;
  font-weight: 500;
  color: #666666;
  text-transform: capitalize;
}

.group-products {
  padding: 10px;
  margin: 10px;
  margin-left: 20px;
  margin-right: 20px;
  background-color: transparent;
  margin-bottom: 20px;
  padding-top: 0;
}

.group-products .item {
  border-radius: 3px;
  background-color: white;
  padding: 20px;

}

.group-products .item h4 {
  margin-bottom: 20px;
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 700;
}

.group-products .item .item-products {
  margin-bottom: 30px;
}

.group-products .item .item-product {
  height: 140px;
  background-color: white;
  cursor: pointer;

}

.group-products .item .item-product .img-box {
  width: 100%;
  height: 100%;
  padding: 5px;

}

.group-products .item .item-product .img-box img {
  width: 100%;
  height: 100%;
}

.group-products .item .item-product h6 {
  margin-top: 2px;
  font-size: 12px;
  color: #878787;
}

.group-products .item a {
  color: #13c7d7 !important;
  display: inline-block;
  font-size: 14px;
}

.el-carousel__indicators {
  display: none;
}

.slick-Prev {
  font-size: 0;
  line-height: 0;
  position: absolute;
  left: 20px;
  top: 50%;
  display: block;
  width: 44px;
  height: 100px;
  padding: 0;
  background-color: white;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  border: 0;
  z-index: 5;
  border-radius: 0 3px 3px 0;
  box-shadow: 1px 0 2px rgba(0, 0, 0, 0.25);
  background-color: white;
}

.slick-Prev .fa-angle-left,
.slick-Next .fa-angle-right {
  color: darkgrey;
  width: 30px;
  height: 30px;
}

.slick-Next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  right: 0px;
  top: 50%;
  display: block;
  width: 44px;
  height: 100px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  border: 0;
  z-index: 5;
  border-radius: 3px 0 0 3px;
  box-shadow: -1px 0 2px rgba(0, 0, 0, 0.25);
  background-color: white;
}


.pargraph {
  text-align: center;
  font-size: 14px;
  color: #f6f6f6;
  /* margin-top:30px !important; */
  margin-bottom: 0;
}

.el-carousel__container{
  height: 550px !important;
}


@media (max-width: 580px) {
 

  .products {
    bottom: -50px;
  }

  .products .row {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .products .row .col-4 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .products .product-item {
    padding: 5px;
    margin-bottom: 10px;
  }

  .products .product-item h3 {
    font-size: 14px;
    font-weight: 500;
  }

  .products .product-item a {
    font-size: 12px;
  }

  .group-products .item {
    margin-bottom: 10px;
    padding: 15px !important;
  }

  .group-products .col-6 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .group-products .item .item-products {
    margin-bottom: 0;
  }

  .top-sm {
    position: absolute !important;
    top: 177px;
    margin-left: 0;
    margin-right: 0;
  }

  .carousel-hg {
    height: 39vh !important;
  }

  .el-carousel__container{
    height: 310px !important;
  }

  .products-slider-wraper-sm {
  }

  .products-slider-sm {
    position: relative !important;
    bottom: 180px;
    margin-left: 0;
    margin-right: 0;
  }

  /* products-slider-item{
  margin: 5px;
} */

  .products-slider-sm h4 {
    font-size: 11px;
    margin-bottom: 10px;
    text-transform: capitalize;
  }

  .products-slider-sm .products-slider-item {
    background-color: white;
    padding: 8px;
    margin: 5px;
    width: 95% !important;
    border-radius: 5px;
  }

  .products-slider p {
    font-size: 10px;
  }

  .products-slider .offer span {
    font-size: 10px;
    padding: 2px;
  }

  .slider {
    height: 300px;
    margin-bottom: 50px;
  }

  .el-carousel__arrow--left,
  .el-carousel__arrow--right {
    display: none !important;
  }

  .products-slider-wraper {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .products-slider .products-slider-item {
    padding: 5px;
    margin: 0;
  }

  .products-slider-wraper h4 {
    margin: 10px;
    padding-left: 0;
  }

  .slick-Next,
  .slick-Prev {
    display: none !important;
  }

  .group-products {
    margin: 0;
  }

  .group-products .me-3 {
    margin-right: 0 !important;
  }

  .group-products .item .item-product {
    height: 130px;
  }

  .menu .item .mt-3 {
    margin-top: 0 !important;
  }

  .search {
    height: 48px;
  }


  .hg-sm {
    height: 280px !important;
  }
}

.carousel-hg {
  height: 80vh;
}




</style>

