<template>
  <div class="footer">
    <button @click.prevent="scrollToTop">Back to top</button>
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6 col-lg-3">
          <div class="item">
            <h4>Get to Know Us</h4>
            <a href="https://www.amazon.jobs/en/">Careers</a>
            <a
              href="https://www.amazon.ca/gp/help/customer/display.html?nodeId=G4N2FJ6WB9RDPEHW&ref_=footer_corpres"
              >Amazon and Our Planet</a
            >
            <a
              href="https://www.amazon.ca/gp/help/customer/display.html?nodeId=GXYZF9M33FRJ5TMA"
              >Modern Slavery Statement</a
            >
            <a href="https://ir.aboutamazon.com/overview/default.aspx"
              >Investor Relations</a
            >
            <a href="https://press.aboutamazon.com/canada-press-center"
              >Press Releases</a
            >
            <a href="https://www.amazon.science/">Amazon Science</a>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-3">
          <div class="item">
            <h4>Make Money with Us</h4>
            <a
              href="https://sell.amazon.ca/?ld=AZFSSOA-CA-footer&ref_=footer_soa&mons_sel_locale=en_CA"
              >Sell on Amazon</a
            >
            <a href="https://supply.amazon.com/?ref_=footer_sta&lang=en-CA"
              >Supply to Amazon</a
            >
            <a href="https://associates.amazon.ca/">Become an Affiliate</a>
            <a
              href="https://brandservices.amazon.ca/?ref=AOCAABRLGNRFOOT&ld=AOCAABRLGNRFOOT"
              >Protect & Build Your Brand</a
            >
            <a
              href="https://sell.amazon.ca/programs/handmade/?ld=AZCAHND-footer&ref_=footer_sell&mons_sel_locale=en_CA"
              >Sell on Amazon Handmade</a
            >
            <a
              href="https://advertising.amazon.com/en-ca?ref=footer_advtsing_amzn_ca"
              >Advertise Your Products</a
            >
            <a href="https://kdp.amazon.com/en_US/?language=en_US"
              >Independently Publish with Us</a
            >
            <a href="https://www.amazon.ca/b?ie=UTF8&node=15576834011"
              >Host an Amazon Hub
            </a>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-3">
          <div class="item">
            <h4>Amazon Payment Products</h4>
            <a
              href="https://www.amazon.ca/dp/B07MJM4F44?plattr=CACBCCFOOT&ref_=nav_cbcc_ca_footer"
              >Amazon.ca Rewards Mastercard</a
            >
            <a
              href="https://www.amazon.ca/gp/browse.html?node=21371066011&ref_=footer_swp"
              >Shop with Points</a
            >
            <a href="https://www.amazon.ca/gp/gc/create?ref_=footer_reload_ca"
              >Reload Your Balance</a
            >
            <a
              href="https://www.amazon.ca/gp/browse.html?node=8438004011&ref_=footer_tfx_ca_en"
              >Amazon Currency Converter</a
            >
            <a
              href="https://www.amazon.ca/gp/browse.html?node=9230166011&ref_=footer_gift_cards_ca_en"
              >Gift Cards</a
            >
            <a
              href="https://www.amazon.ca/gp/browse.html?node=17321486011&ref_=footer_cash_ca_en"
              >Amazon Cash</a
            >
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-3">
          <div class="item">
            <h4>Let Us Help You</h4>
            <a
              href="https://www.amazon.ca/gp/help/customer/display.html?nodeId=GE66DNRRQVDZAR5E&ref_=footer_shiprates"
              >Shipping Rates & Policies</a
            >
            <a href="https://www.amazon.ca/amazonprime?ref_=footer_prime"
              >Amazon Prime</a
            >
            <a
              href="https://www.amazon.ca/gp/css/returns/homepage.html?ref_=footer_hy_f_4"
              >Returns Are Easy</a
            >
            <a
              href="https://www.amazon.ca/hz/mycd/myx?ref_=footer_myk#/home/content/booksAll/dateDsc/"
              >Manage your Content and Devices</a
            >
            <a
              href="https://www.amazon.ca/product-safety-alerts?ref_=footer_bsx_ypsa"
              >Recalls and Product Safety Alerts</a
            >
            <a
              href="https://www.amazon.ca/registries?ref_=nav_footer_registry_giftlist_desktop"
              >Registry & Gift List</a
            >
            <a href="https://www.amazon.ca/hz/contact-us/foresight/hubgateway"
              >Customer Service</a
            >
          </div>
        </div>
      </div>
      <el-divider style="opacity:.3;    border-top: 1px #6e6e6e !important var(--el-border-style);
    display: block;
    height: 1px;
    margin: 24px 0 !important ;
    width: 100%;" />

      <div class="options">
        <div class="logo">
          <router-link to="/"><img src="../assets/logo3.png" width="90" alt="" /></router-link>
        </div>

        <!-- <div class="lang">
          <i class="fa-solid fa-globe"></i>
          <span>English</span>
        </div> -->

        <el-popover
          placement="bottom"
          :width="200"
          trigger="hover"
          class="me-3"
        >
          <template #reference>
            <div
              class=""
              style="
                font-size: 14px;
                font-weight: 600;
                padding: 8px;
                border: 1px solid rgb(153 153 153);
                border-radius: 3px;
                cursor: pointer;
                opacity:.8;
              "
            >
              <i class="fa-solid fa-globe me-2"></i>
              English
              <i class="fa-solid fa-angle-down ms-2"></i>
            </div>
          </template>
          <div class="text-start">
            <el-radio-group v-model="radio2">
              <el-radio value="1" size="large">English</el-radio>
              <el-radio value="2" size="large">franch</el-radio>
            </el-radio-group>

            <a href="" class="text-primary d-inline-block mb-4 mt-2">learn more</a>

            <div class="">
              <div class="d-flex">
                <img
                  src="../assets/flag.png"
                  class="me-2"
                  width="20"
                  height="20"
                  alt=""
                />
                <p style="font-size: 13px">You are shopping in Amazon.ca</p>
              </div>

              <a href="" class="text-primary">chang country/reigon</a>
            </div>
          </div>
        </el-popover>

        <div class="country ms-3">
          <img src="../assets/flag.png" width="20" alt="" />
          <span>canada</span>
        </div>
      </div>
    </div>

    <div class="footer-bottom">
      <div class="container">
        <div class="row mb-4">
          <div class="col-6 col-md-6 col-lg-3">
            <div class="fbtmItem">
              <div class="">
                <a
                  href="https://music.amazon.ca/account/manage?0=%2F&1=&subView=undefined&successUrl=Lz9yZWY9ZG1fYWZmX2Ftel9jYQ%3D%3D&cancelUrl=Lz9yZWY9ZG1fYWZmX2Ftel9jYQ%3D%3D&customerId=ARA99XF4M8ZFQ&deviceType=A16ZV8BU3SN1N3&deviceId=13249514547769965&musicTerritory=CA&locale=en_CA&currencyOfPreference=undefined"
                >
                  <h5>Amazon Music</h5>
                  <p>Stream millions of songs</p>
                </a>
              </div>

              <div class="">
                <a
                  href="https://advertising.amazon.com/en-ca?ref=footer_advtsing_amzn_ca"
                >
                  <h5>Amazon Advertising</h5>
                  <p>Find, attract and engage customers</p>
                </a>
              </div>
            </div>
          </div>

          <div class="col-6 col-md-6 col-lg-3">
            <div class="fbtmItem">
              <div class="">
                <a
                  href="https://www.amazon.ca/business/register/org/landing?ref_=footer_retail_b2b"
                >
                  <h5>Amazon Business</h5>
                  <p>Everything for your business</p>
                </a>
              </div>

              <div class="">
                <a
                  href="https://www.amazon.ca/gp/browse.html?node=16055945011&ref_=_ca_footer_drive"
                >
                  <h5>Amazon Drive</h5>
                  <p>Cloud storage from Amazon</p>
                </a>
              </div>
            </div>
          </div>

          <div class="col-6 col-md-6 col-lg-3">
            <div class="fbtmItem">
              <div class="">
                <a
                  href="https://aws.amazon.com/ar/what-is-cloud-computing/?sc_channel=EL&sc_campaign=CA_amazonfooter"
                >
                  <h5>Amazon Web Services</h5>
                  <p>Scalable Cloud Computing Services</p>
                </a>
              </div>

              <div class="">
                <a href="https://www.goodreads.com/">
                  <h5>Goodreads</h5>
                  <p>Book reviews & recommendations</p>
                </a>
              </div>
            </div>
          </div>

          <div class="col-6 col-md-6 col-lg-3">
            <div class="fbtmItem">
              <div class="">
                <a href="https://www.imdb.com/">
                  <h5>IMDb</h5>
                  <p>Movies, TV & Celebrities</p>
                </a>
              </div>

              <div class="">
                <a
                  href="https://www.amazon.ca/gp/browse.html?node=16055944011&ref_=_gno_p_foot"
                >
                  <h5>Amazon Photos</h5>
                  <p>Unlimited Photo Storage Free With Prime</p>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-4">
          <div class="d-flex links">
            <a
              href="https://www.amazon.ca/gp/help/customer/display.html?nodeId=GLSBYFE9MGKKQXXM&ref_=footer_cou"
              >Conditions of Use</a
            >
            <a
              href="https://www.amazon.ca/gp/help/customer/display.html?nodeId=GX7NJQ4ZB8MHFRNJ&ref_=footer_privacy"
              >Privacy Notice</a
            >
            <a
              href="https://www.amazon.ca/gp/help/customer/display.html?nodeId=GLVB9XDF9M8MU7UZ&ref_=footer_iba"
              >Interest-Based Ads</a
            >
          </div>

          <p class="pargraph">
            © 1996-2024, Amazon.com, Inc. or its affiliates
          </p>

          <p class="pargraph mt-4">
            Amazon.com.ca ULC | 40 King Street W 47th Floor, Toronto, Ontario,
            Canada, M5H 3Y2 |1-877-586-3230
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footerSec",
  props: {},
  setup() {
    function scrollToTop() {
      window.scrollTo(0, 0);
    }

    const radio1 = '1'
    const radio2 = '1'

    return {
      scrollToTop,
      radio1,
      radio2
    };
  },
};
</script>

<style>
.footer {
  background-color: #232f3e;
  color: white;
  padding-bottom: 20px;
}

.footer button {
  width: 100%;
  height: 50px;
  border: none;
  color: white;
  text-align: center;
  background-color: #37475a;
  font-size: 13px;
}

.footer .item {
  margin-top: 30px;
}

.footer .item h4 {
  font-weight: 600;
  margin-bottom: 20px;
  text-transform: capitalize;
  font-size: 15px;
}

.footer .item a {
  text-transform: capitalize;
  margin-bottom: 5px;
  color: #d2d2d2 !important;
  display: block;
  font-size: 14px;
}

.footer .options {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 60px;
}

.footer .logo {
  margin-right: 40px;
}

.footer .logo img {
}

.footer .lang {
  padding: 6px;
  border: 1px solid rgb(214, 214, 214);
  border-radius: 3px;
  margin-right: 20px;
  cursor: pointer;
}

.footer .lang span {
  margin-left: 10px;
  display: inline-block;
}

.footer .country {
  padding: 6px;
  border: 1px solid rgb(153, 153, 153);
  border-radius: 3px;
  cursor: pointer;
  opacity: .8;
  text-transform: capitalize;
}

.footer .country span {
  margin-left: 10px;
  display: inline-block;
}

.footer-bottom {
  background-color: #131a22 !important;
  padding-top: 60px;
  padding-bottom: 15px;
}

.footer-bottom .fbtmItem {
}

.footer-bottom .fbtmItem h5 {
  font-size: 15px;
  color: #e5e5e5;
  margin-bottom: 0px;
  text-transform: capitalize;
}

.footer-bottom .fbtmItem p {
  font-size: 12px;
  color: #c3c3c3;
  text-transform: capitalize;
}

.footer .links {
  align-items: center;
  justify-content: center;
}

.footer .links a {
    display: inline-block;
    text-transform: capitalize;
    margin-right: 10px;
    color: #c1c1c1;
    font-size: 12px;
    margin-bottom: 6px;
}

.footer p {
  font-size: 12px;
  color: #f6f6f6;
  /* margin-top: 10px; */
  margin-bottom: 10px;
}

.pargraph {
  text-align: center;
  font-size: 14px;
  color: #f6f6f6;
  /* margin-top:30px !important; */
  margin-bottom: 0;
}

@media (max-width: 580px) {
  .footer .options {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .footer-bottom .fbtmItem {
    margin-bottom: 50px;
  }

  .hg-sm {
    height: 280px !important;
  }
}
</style>