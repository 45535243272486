<template>
    <div class="menu" style="">
        <div
          class="menu-inner"
          style="
            background:linear-gradient(92.41deg,#003d32,#04734b);
            color: #fff;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 20px;
            padding-right: 20px;
          "
        >
          <div class="item">
           <router-link to="/">
            <img src="../assets/logo3.png" class="logo" width="100" alt="" />
            <sub style="bottom:0.2em" >.ca</sub>
           </router-link>
          </div>
  
          <div class="item sm-none">
            <div class="d-flex justify-content-center align-items-center">
              <div class="icon d-flex justify-content-center align-items-center">
                <i class="fa-solid fa-location-dot"></i>
              </div>
              <div class="text">
                <h5>Delivering to Balzac T4B 2T</h5>
                <h6 class="fw-bold">Update Location</h6>
              </div>
            </div>
          </div>
  
          <div class="item search sm-none">
            <div class="mt-3 me-2">
              <div class="input-group mb-3">
                <button
                  class="btn btn-outline-secondary dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  All
                </button>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" href="#">All Departments</a></li>
                <li><a class="dropdown-item" href="#">Alexa Skills</a></li>
                <li><a class="dropdown-item" href="#">Amazon Devices</a></li>
                <li><a class="dropdown-item" href="#">Amazon Resale</a></li>
                <li><a class="dropdown-item" href="#">Apps & Games</a></li>
                <li><a class="dropdown-item" href="#">Audible Audiobooks</a></li>
                <li><a class="dropdown-item" href="#">Automotive</a></li>
                <li><a class="dropdown-item" href="#">Baby</a></li>
                <li><a class="dropdown-item" href="#">Beauty</a></li>
                <li><a class="dropdown-item" href="#">Books</a></li>
                <li><a class="dropdown-item" href="#">Clothing, Shose&jewellery</a></li>
                <li><a class="dropdown-item" href="#">Women</a></li>
                <li><a class="dropdown-item" href="#">Men</a></li>
                <li><a class="dropdown-item" href="#">Girls</a></li>
                <li><a class="dropdown-item" href="#">Boys</a></li>
                <li><a class="dropdown-item" href="#">Baby</a></li>
                <li><a class="dropdown-item" href="#">Electronics</a></li>
                <li><a class="dropdown-item" href="#">Gift Cards</a></li>
                <li><a class="dropdown-item" href="#">Grocery</a></li>
                <li><a class="dropdown-item" href="#">Handmade</a></li>
                </ul>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search Amazon.ca"
                  aria-label="Text input with dropdown button"
                />
                <span class="icon"
                  ><i class="fa-solid fa-magnifying-glass"></i
                ></span>
              </div>
            </div>
          </div>
  
          <div class="item sm-none">
            <el-popover placement="bottom" :width="200" trigger="hover">
              <template #reference>
                <div class="" style="font-size: 14px; font-weight: 600">
                  <img src="../assets/flag.png" width="20" style="position:relative;bottom:1px" alt="" /> EN
                  <i class="fa-solid fa-angle-down" style="    color: #a7a7a7;
    position: relative;
    top: 1px;
    font-size: 11px;" ></i>
                </div>
              </template>
              <div class="text-start">
                <el-radio-group v-model="radio1">
                  <el-radio value="1" size="large">English</el-radio>
                  <el-radio value="2" size="large">franch</el-radio>
                </el-radio-group>
  
                <a href="" class="text-primary d-inline-block mb-4">learn more</a>
  
                <div class="">
                  <div class="d-flex">
                    <img
                      src="../assets/flag.png"
                      class="me-2"
                      width="18"
                      height="18"
                      alt=""
                    />
                    <p style="font-size: 13px">You are shopping in Amazon.ca</p>
                  </div>
  
                  <a href="" class="text-primary">chang country/reigon</a>
                </div>
              </div>
            </el-popover>
          </div>
  
          <div class="sm-none" style="">
            <el-popover placement="bottom" :width="400" trigger="hover">
              <template #reference>
                <div class="">
                  <h6 class="sign-in">Hello, sign in</h6>
                  <div class="d-flex justify-content-around align-items-center">
                    <h6 class="account">Account & list</h6>
                    <i class="fa-solid fa-angle-down" style="    color: #a7a7a7;
    position: relative;
    top: 1px;
    font-size: 11px;" ></i>
                  </div>
                </div>
              </template>
              <div class="text-center modal-signIn">
                <el-button type="danger" class="sign-in-btn"> <a href="https://www.amazon.ca/ap/signin?openid.pape.max_auth_age=0&openid.return_to=https%3A%2F%2Fwww.amazon.ca%2Fgp%2Fcart%2Fview.html%3Fref_%3Dnav_ya_signin&openid.identity=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select&openid.assoc_handle=caflex&openid.mode=checkid_setup&openid.claimed_id=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select&openid.ns=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0">Sign in</a> </el-button>
  
                <p>New Customer <span>Start here</span></p>
                <div
                  class="signIn-links d-flex justify-content-around text-start m-auto"
                >
                  <div class="">
                    <div class="">
                      <h4>Your List</h4>
                    </div>
                    <div class="">
                      <a href="">Create a wish List</a>
                    </div>
  
                    <div class="">
                      <a href="">wish from any website</a>
                    </div>
  
                    <div class="">
                      <a href="">Create a wish List</a>
                    </div>
                  </div>
  
                  <div class="">
                    <div class="">
                      <h4>Your Account</h4>
                    </div>
                    <div class="">
                      <a href="">Create a wish List</a>
                    </div>
  
                    <div class="">
                      <a href="">wish from any website</a>
                    </div>
  
                    <div class="">
                      <a href="">Create a wish List</a>
                    </div>
  
                    <div class="">
                      <a href="">Create a wish List</a>
                    </div>
  
                    <div class="">
                      <a href="">wish from any website</a>
                    </div>
  
                    <div class="">
                      <a href="">Create a wish List</a>
                    </div>
                  </div>
                </div>
              </div>
            </el-popover>
          </div>
  
          <div class="item sm-none">
            <div
              class="d-flex flex-column"
              style="font-size: 13px; line-height: 1"
            >
              <span class="me-1 d-block">Returns </span>
              <span class="orders">& Orders</span>
            </div>
          </div>
  
          <!-- <div class="item d-flex d-lg-none">
            <div class="me-3">
              <span>sign in</span>
              <i
                class="fa-solid fa-chevron-right"
                style="font-size: 10px; margin-right: 10px; margin-left: 5px"
              ></i>
              <i class="fa-regular fa-user" style="font-size: 20px"></i>
            </div>
  
            <div class="cart-img">
              <img src="../assets/cart.png" width="30" alt="" />
              <span>{{cartItemCount}}</span>
            </div>
          </div> -->
  
        
            <a href="https://www.amazon.ca/gp/cart/view.html?ref_=nav_cart">
            <div class="item sm-none d-flex">
              <div class="cart-img">
                <img src="../assets/cart.png" width="40" alt="" />
                <span  >{{cartItemCount}}</span>
              </div>
              <span class="cart" style="font-size: 14px;" >Cart</span>
            </div>
          </a>




        </div>
  
        <div class="item search d-lg-none" style="background-color: #232f3e">
          <div class="mt-3 me-2">
            <div class="input-group mb-3">
              <button
                class="btn btn-outline-secondary dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                All
              </button>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="#">All Departments</a></li>
                <li><a class="dropdown-item" href="#">Alexa Skills</a></li>
                <li><a class="dropdown-item" href="#">Amazon Devices</a></li>
                <li><a class="dropdown-item" href="#">Amazon Resale</a></li>
                <li><a class="dropdown-item" href="#">Apps & Games</a></li>
                <li><a class="dropdown-item" href="#">Audible Audiobooks</a></li>
                <li><a class="dropdown-item" href="#">Automotive</a></li>
                <li><a class="dropdown-item" href="#">Baby</a></li>
                <li><a class="dropdown-item" href="#">Beauty</a></li>
                <li><a class="dropdown-item" href="#">Books</a></li>
                <li><a class="dropdown-item" href="#">Clothing, Shose&jewellery</a></li>
                <li><a class="dropdown-item" href="#">Women</a></li>
                <li><a class="dropdown-item" href="#">Men</a></li>
                <li><a class="dropdown-item" href="#">Girls</a></li>
                <li><a class="dropdown-item" href="#">Boys</a></li>
                <li><a class="dropdown-item" href="#">Baby</a></li>
                <li><a class="dropdown-item" href="#">Electronics</a></li>
                <li><a class="dropdown-item" href="#">Gift Cards</a></li>
                <li><a class="dropdown-item" href="#">Grocery</a></li>
                <li><a class="dropdown-item" href="#">Handmade</a></li>

              </ul>
              <input
                type="text"
                class="form-control"
                placeholder="Search Amazon.ca"
                aria-label="Text input with dropdown button"
              />
              <span class="icon"
                ><i class="fa-solid fa-magnifying-glass"></i
              ></span>
            </div>
          </div>
        </div>
  
        <el-drawer
          v-model="drawer"
          direction="ltr"
          title="I am the title"
          size="25%"
          :with-header="false"
        >
        <div class="user d-flex">
          <i class="fa-regular fa-user me-3" style="font-size: 19px"></i>
          <h4>Hello, User</h4>
        </div>

       <div class="trinding">
        <h5>Trending </h5>

        <a href="https://www.amazon.ca/gp/bestsellers/?ref_=nav_em_cs_bestsellers_0_1_1_2" class="d-block mb-3">Best Sellers</a>
        <a href="https://www.amazon.ca/gp/new-releases/?ref_=nav_em_cs_newreleases_0_1_1_3" class="d-block mb-3" >New Releases</a>
        <a href="https://www.amazon.ca/gp/movers-and-shakers/?ref_=nav_em_ms_0_1_1_4" class="d-block mb-3" >Movers & Shakers</a>
       </div>

        <el-divider />

        <h5 class="DigitalContent">Digital Content and Devices</h5>
        <el-menu
        default-active="2"
        class="el-menu-vertical-demo"
        @open="handleOpen"
        @close="handleClose"
      >

  

     
        <el-sub-menu index="1">
          <template #title>
            <el-icon><location /></el-icon>
            <span>Echo & Alexa</span>
          </template>
          <el-menu-item-group title="Group One">
            <el-menu-item index="1-1">item one</el-menu-item>
            <el-menu-item index="1-2">item two</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group title="Group Two">
            <el-menu-item index="1-3">item three</el-menu-item>
          </el-menu-item-group>
          <el-sub-menu index="1-4">
            <template #title>item four</template>
            <el-menu-item index="1-4-1">item one</el-menu-item>
          </el-sub-menu>
        </el-sub-menu>
        <el-menu-item index="2">
          <el-icon><icon-menu /></el-icon>
          <span>Kindle</span>
        </el-menu-item>
       
        <el-menu-item index="3" @click="innerDrawer = true">
          <el-icon><setting /></el-icon>
          <span>Prime Video</span>
        </el-menu-item>

        
        <el-menu-item index="4" @click="innerDrawer = true">
          <el-icon><setting /></el-icon>
          <span>Amazon Music </span>
        </el-menu-item>

        
        <el-menu-item index="5" @click="innerDrawer = true">
          <el-icon><setting /></el-icon>
          <span>Appstore for Android</span>
        </el-menu-item>

        <el-sub-menu index="6">
          <template #title>
            <el-icon><location /></el-icon>
            <span>Navigator One</span>
          </template>
          <el-menu-item-group title="Group One">
            <el-menu-item index="6-1">item one</el-menu-item>
            <el-menu-item index="6-2">item two</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group title="Group Two">
            <el-menu-item index="6-3">item three</el-menu-item>
          </el-menu-item-group>
          <el-sub-menu index="6-4">
            <template #title>item four</template>
            <el-menu-item index="6-4-1">item one</el-menu-item>
          </el-sub-menu>
        </el-sub-menu>
      </el-menu>


      <!-- <el-button >Click me!</el-button> -->
      <el-drawer
        v-model="innerDrawer"
        :append-to-body="true"
        direction="ltr"
        size="25%"
        :before-close="handleClose"
      >

      <div class="inner-aside p-2">
        <button type="button" aria-label="Close this dialog" style="background-color:#EAEDED;width:100%; padding:10px 15px;text-align:start;font-size:15px;" class="d-block mb-4 fw-bold el-drawer__close-btn  ps-4"  > <i class="fa-solid fa-arrow-left me-2"></i
          >  MAIN MEUN</button>
        <h5 class="fw-bold mb-4  ps-4" >Fire Tablets</h5>
        <a href="" class="d-block mb-4  ps-4">Fire 7</a>
        <a href="" class="d-block mb-4  ps-4" >Fire HD 8</a>
        <a href="" class="d-block mb-4  ps-4" >Fire HD 8 pro</a>
        <a href="" class="d-block mb-4  ps-4" >Fire 7 Kids</a>
        <a href="" class="d-block mb-4  ps-4" >Fire HD 7 Kids</a>
        <el-divider />
        <h5 class="fw-bold mb-4 mt-4 ps-4" >Amazon Fire TV</h5>
        <a href="" class="d-block mb-4  ps-4" >Fire HD 8</a>
        <a href="" class="d-block mb-4  ps-4" >Fire HD 8 pro</a>
        <a href="" class="d-block mb-4  ps-4" >Fire 7 Kids</a>
      </div>

        
      </el-drawer>

        </el-drawer>
  
        <div class="sup-nav">
          <!-- <div class="item">
                <el-button @click.prevent="visible = true">
                    <i class="fa-solid fa-bars"></i>
                    <a href="">All</a>
  
                </el-button>
            </div> -->
          <div class="item">
            <a href="" @click.prevent="drawer = true"
              ><i class="fa-solid fa-bars me-1" style="font-size:20px;position:relative;top:2px;" ></i> All</a
            >
            <!-- <el-button type="primary" style="margin-left: 16px" >
                  open
                </el-button> -->
          </div>
          <div class="item">
            <a href="https://www.amazon.ca/hz/contact-us/foresight/hubgateway"  >Customer Service</a>
          </div>
  
          <div class="item">
            <a href="https://www.amazon.ca/deals?ref_=nav_cs_gb">Deals Store</a>
          </div>
  
          <div class="item">
            <a href="https://www.amazon.ca/gp/buyagain?ref_=nav_cs_buy_again">Buy Again</a>
          </div>
  
          <div class="item">
            <a href="https://www.amazon.ca/Best-Sellers-generic/zgbs/?ref_=nav_cs_bestsellers">Best Sellers</a>
          </div>
  
          <div class="item">
            <a href="https://www.amazon.ca/gp/prime/pipeline/membersignup">Prime</a>
          </div>
  
          <div class="item">
            <a href="https://www.amazon.ca/gp/new-releases/?ref_=nav_cs_newreleases">New Releases</a>
          </div>

          <div class="item">
            <a href="https://www.amazon.ca/gp/browse.html?node=2206275011&ref_=nav_cs_home">Home</a>
          </div>
  
          <div class="item">
            <a href="https://www.amazon.ca/books-used-books-textbooks/b/?ie=UTF8&node=916520&ref_=nav_cs_books">Books</a>
          </div>
  
          <div class="item">
            <a href="https://www.amazon.ca/gcx/-/gfhz/?ref_=nav_cs_giftfinder">Gift Ideas	</a>
          </div>
  
          <div class="item">
            <a href="https://www.amazon.ca/gp/browse.html?node=9230166011&ref_=nav_cs_gc">Gift Cards </a>
          </div>
  
          <div class="item">
            <a href="https://www.amazon.ca/gp/browse.html?node=21204935011&ref_=nav_cs_fashion">Fashion</a>
          </div>
  

  
          <div class="item">
            <a href="">Audible</a>
          </div>
  
          <div class="item">
            <a href="">Music</a>
          </div>
  
          <div class="item">
            <a href="">Beauty & Personal Care</a>
          </div>
  
          <div class="item">
            <a href="">Computers</a>
          </div>
        </div>
      </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from 'vuex';
import { computed } from 'vue';

export default {
  name: 'navBar',

  setup(){

    const drawer = ref(false);
    const radio1 = "1";
    const innerDrawer = ref(false)

    const store = useStore();
    
    const cartItemCount = computed(() => store.getters.cartItemCount);
    

    return{
        drawer,
        radio1,
        innerDrawer,
        cartItemCount
    }
  }

}


</script>


<style>

.el-menu {
    align-items: center;
    color: white;
  }
  
  .search {
    min-width: 53%;
  }
  
  .search .input-group .btn {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  
  .menu .text {
    margin-left: 10px;
  }
  
  .menu .text h5 {
    font-size: 12px;
    font-weight: 300;
    margin-bottom: 0px;
    opacity: 0.7;
  }
  
  .menu .text h6 {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0px;
  }
  
  .search .icon {
    /* display: inline-block; */
    color: white;
    background-color: #a9176f;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
  }
  
  .fa-magnifying-glass {
    color: rgb(255, 255, 255);
    font-size: 20px;
  }
  
  .search button {
    background-color: #E6E6E6;
    font-size: 12px;
    border: none;
  }
  
  .menu .el-dropdown {
    color: white;
  }
  
  .sign-in {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 0;
  }
  
  .account {
    font-weight: 600;
    margin-right: 4px;
    margin-bottom: 3px;
    text-transform: capitalize;
    font-size: 15px;
  }
  
  .menu .el-menu-item {
    line-height: 1.5;
  }
  
  .menu .orders {
    font-weight: 600;
    font-size: 15px;
    display: block;
  }
  
  .cart {
    padding-top: 11px;
    font-weight: 700;
  }
  
  .cart-img {
    position: relative;
  }
  
  .cart-img span {
    color: #ff9900;
    position: absolute;
    top: -5px;
    right: 13px;
    font-weight: 700;
    font-size: 14px;
  }
  
  .nav-item {
  }
  
  .menu .item {
    padding: 2px;
  }
  
  .menu .item:hover {
    border: 1px solid #fff;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .menu .search:hover {
    border: none;
    cursor: none;
  }
  
  .sup-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #085749;
    color: white;
    /* padding-bottom: 15px; */
    padding-left: 20px;
    padding-right: 20px;
  }
  
  .sup-nav .item {
    border-radius: 3px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  
  .sup-nav .item:hover {
    border: 1px solid #fff;
  }
  
  .sup-nav .item a {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
  }
  
  .modal-signIn .sign-in-btn {
    background-color: #f2bc6d;
    padding: 15px 100px 15px 100px;
    border: none;
    margin-bottom: 10px;
  }
  
  .modal-signIn p {
    text-transform: capitalize;
  }
  
  .modal-signIn p span {
    color: rgb(81, 174, 241);
  }
  
  .modal-signIn .signIn-links {
    padding: 10px;
  }
  
  .modal-signIn .signIn-links h4 {
    font-size: 20px;
    font-weight: 700;
  }
  
  .modal-signIn .signIn-links a {
    display: inline-block;
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 12px;
  }
  
  .dropdown-menu {
    height: 455px;
    overflow-y: scroll;
    width: 250px;
    padding-top: 2px;
  }

  .dropdown-item{
    padding: 4px 4px;
    font-size: 14px;
  }


  
@media (max-width: 580px) {
    .menu,
    .sup-nav {
      max-width: 100%;
      overflow: hidden;
    }
  
    .menu-inner {
      padding-left: 5px !important;
      padding-right: 5px !important;
    }
  
    .menu .logo {
      width: 80px;
    }
  
    .group-products .item h4 {
      font-size: 16px;
    }
  
    .menu .text h5 {
      font-size: 8px;
    }
  
    .menu .text h6 {
      font-size: 6px;
    }
  
    .sm-none {
      display: none;
    }
  
    .sup-nav {
      width: 100%;
      overflow-x: scroll;
    }
  
    .sup-nav .item {
      padding: 5px;
      white-space: nowrap;
    }
  
    .sup-nav .item a {
      font-size: 14px;
    }

    .el-drawer.ltr{
      width: 75% !important;
    }
}

.el-drawer__body{
  padding: 0 !important;
}

.user{
  background-color: #232F3E;
  padding: 15px 30px;
  color: white;
  align-items: center;
}

.user h4{
  margin-bottom: 0;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 16px;
}

.trinding{
  padding: 15px 30px;
}

.trinding h5{
  font-weight: 700;
  margin-bottom: 15px;
}

.trinding a{
  font-size: 15px;
}

.el-sub-menu__title, .el-menu-item{
  padding-left: 0 !important;
}

.el-sub-menu .el-menu-item{
  padding-left: 30px !important;
}

.DigitalContent{
  font-weight: 700;
  margin-bottom: 10px;
  margin-top: 15px;
  padding-left: 30px;
}

.el-drawer__header{
  margin-bottom: 0 !important;
}

.form-control:focus{
  box-shadow: none;
}

</style>