import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Checkout from '@/views/checkout.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/Product',
    name: 'product',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "product" */ '../views/Product.vue')
    }
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: function () {
      return import(/* webpackChunkName: "checkout" */ '../views/checkout.vue')
    }
  },
  {
    path: '/cart',
    name: 'cart',
    component: function () {
      return import(/* webpackChunkName: "cart" */ '../views/cart.vue')
    }
  },
  {
    path: '/test',
    name: 'test',
    component: function () {
      return import(/* webpackChunkName: "test" */ '../views/test.vue')
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
