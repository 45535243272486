import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import VueImageZoomer from 'vue-image-zoomer'
import 'vue-image-zoomer/dist/style.css';

import store from './store';

const app = createApp(App)

app.use(VueImageZoomer);
app.use(router)
app.use(ElementPlus)

app.use(store)


import VueCreditCardValidation from 'vue-credit-card-validation';

app.use(VueCreditCardValidation);

app.mount('#app')

