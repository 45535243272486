// store/index.js
import { createStore } from 'vuex';

export default createStore({
    state: {
        cart: JSON.parse(localStorage.getItem('cart')) || []  // Load cart from localStorage or initialize as an empty array
    },
    mutations: {
        addToCart(state, product) {
            const item = state.cart.find(i => i.id === product.id);
            if (item) {
                item.quantity += 1;
            } else {
                state.cart.push({ ...product, quantity: 1 });
                console.log('prosuct id'+ product.id)
            }
            this.commit('saveCart');
        },
        removeFromCart(state, productId) {
            state.cart = state.cart.filter(item => item.id !== productId);
            this.commit('saveCart');
        },
        removeAllCart(state) {
            const cart = []  
            state.cart = cart;
            this.commit('saveCart');
            
        },
        saveCart(state){
            
            localStorage.setItem('cart', JSON.stringify(state.cart));  // Save the cart to localStorage
        },
        loadCart(state) {
            const cart = JSON.parse(localStorage.getItem('cart'));
            if (cart) {
                state.cart = cart;
            }
        }
    },
    getters: {
        cartItemCount(state) {
            if(state.cart){
                return state.cart.reduce((total, item) => total + item.quantity, 0);

            }
        },
        cartItems(state) {
            return state.cart;
        }
    },
    actions: {
        addToCart({ commit }, product) {
            commit('addToCart', product);
        },
        removeFromCart({ commit }, productId) {
            commit('removeFromCart', productId);
        },
        initializeCart({ commit }) {
            commit('loadCart');
        },
        removeAllCart({ commit }) {
            commit('removeAllCart');
        },
    }
});
